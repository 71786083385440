@use '@angular/material' as mat;

@include mat.all-component-typographies();
@include mat.core();


$rip-theme-primary: mat.define-palette((
	100: #ffd8b8,
	500: #ff7737,
	700: #ff6a0c,
	contrast: (
		100: #000000,
		500: #ffffff,
		700: #ffffff,
	),
));
$rip-theme-accent: mat.define-palette(mat.$blue-palette);
$rip-theme-warn: mat.define-palette(mat.$red-palette);

$rip-theme: mat.define-light-theme(
		$rip-theme-primary,
		$rip-theme-accent,
		$rip-theme-warn
);


$rip-primary: map-get($rip-theme, primary);
$rip-accent: map-get($rip-theme, accent);
$rip-warn: map-get($rip-theme, warn);
$rip-foreground: map-get($rip-theme, foreground);
$rip-background: map-get($rip-theme, background);


/*
$rip-primary:
$rip-accent:
$rip-warn:
	default
	lighter
	darker

$rip-foreground:
	base
	divider
	dividers
	disabled
	disabled-button
	disabled-text
	hint-text
	secondary-text
	icon
	icons
	text
	slider-off
	slider-off-active

$rip-background:
	status-bar
	app-bar
	background
	hover
	card
	dialog
	disabled-button
	raised-button
	focused-button
	selected-button
	selected-disabled-button
	disabled-button-toggle
*/
